export const API_URL = "https://api.secondstage.app"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#005157', '#00afaa'],
        home_question: ['#005157', '#00afaa'],
        applausometro: ['#00afaa', '#005157'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#3a4de4', '#00d8a1'],
        wordcloud: ['#005157', '#00afaa'],
        qna: ['#005157', '#00afaa'],
    },
    primary: '#00afaa',
    secondary: '#263238',
    accent: '#00afaa',
    text: '#eceff1',
    background:"#005157",
    poll:{
        default:"rgba(0,175,170,0.39)",
        answered:"#00afaa",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}