import React, {useEffect, useState} from "react";
import Applausometro from "./Applausometro";
import Question from "./Questions/Question";
import {useParams} from "react-router-dom";
import {firestore} from "../firebase/clientApp";
import {Box, CircularProgress, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useCollection, useDocument} from "react-firebase-hooks/firestore";
import {DisplayName} from "./DisplayName";
import {PinnedQuestions} from "./PinnedQuestions";
import ToolpressQuestion from "./Questions/types/ToolpressQuestion";
import qea from "../assets/evento/qea.png"
import pleshDark from "../theme/pleshDark";

export const LogoBox = ({logo}) => {
    const theme = useTheme()
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return(
        <Box style={{width:'100%'}} sx={{/*position: 'fixed', top:0, right: 0*/}}>
            <img style={{ margin:'auto', width: matches ? '100%' : '50%', height:'100%', objectFit:'cover'}} src={logo}/>
            <br/>
            <br/>
            <img style={{ margin:'auto', width:'100px', height:'100%', objectFit:'cover'}} src={qea}/>
        </Box>
    )
}

export default function VoteContent({user, event, loading, userData, setUserData}) {
    const {id} = useParams()
    const [idPlaying, setIdPlaying] = useState('')
    const [eventPlaying, setEventPlaying] = useState('')
    const [pinned, pinnedLoading, pinnedError] = useCollection(firestore.collection('users/plesh/events/' + id + '/queue').where("pinned", "==",true), {})
    const url = 'users/plesh/events/'
    const [theme, themeLoading, themeError] = useDocument(firestore.collection('users/plesh/events/' + id + '/themes').doc(event?.data().theme))

    useEffect(() => {
        getIdPlaying()
    }, [])

    useEffect(() => {

    }, [userData]);

    function getIdPlaying() {
        firestore.collection(url).doc(id).onSnapshot(s => {
            let e = s.data().elementPlaying
            let g = s.data().playing
            setIdPlaying(e)
            setEventPlaying(g)
        })
    }

    return (
        <Box>
            <Stack direction={"column"} justifyContent={"start"} alignContent={"center"}>
                {
                    event.data().autenticazione &&
                    <DisplayName userData={userData} setUserData={setUserData}/>
                }
                {
                    true ?
                        <ToolpressQuestion elementPlaying={{id: "Oo5AId2xyGjixKIEmZUO", conferenza: "3rqChAjvvLJZvNoVuxTM"}}/>
                        :
                        <Stack mt={2} px={3}>
                            <Typography fontWeight={'bold'} variant={'h3'}>
                                The event will take place
                                on October 10th at 10 a.m.
                            </Typography>
                            <br/>
                            <Typography color={pleshDark.palette.accent.main} variant={'h3'}>
                                Thank you!
                            </Typography>
                        </Stack>
                }
                {!pinnedLoading && !pinnedError && pinned ?
                    event.data().autenticazione
                        ?
                        (userData.displayName !== '' && userData.displayName) &&
                        <PinnedQuestions questions={pinned}/>
                        :
                        <PinnedQuestions questions={pinned}/>
                    :
                    null
                }
            </Stack>
            {loading ?
                <Box sx={{textAlign: 'center'}}>
                    <CircularProgress/>
                </Box>
                :
                event.data().applausometro ?
                    event.data().autenticazione
                    ?
                    (userData.displayName !== '' && userData.displayName) &&
                        <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                    :
                    <Applausometro url={url + id} idPlaying={idPlaying} eventPlaying={eventPlaying} user={user}/>
                :
                    <></>
            }
            {(theme && !themeLoading && !themeError && !loading) ?
                event.data().autenticazione
                    ?
                    (userData.displayName !== '' && userData.displayName) &&
                        <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                    :
                    <Question event={event} idPlaying={idPlaying} url={url + id} user={user} theme={theme.data()}/>
                :
                <></>
            }
        </Box>

    )
}
