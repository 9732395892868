import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Vote from "./pages/Vote";
import React from "react";
import {Box, CssBaseline, ThemeProvider} from "@mui/material";

function App() {
    return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <div className="App">
                <Box height={'10%'} position={'fixed'} bottom={0} left={0} right={0}
                     backgroundColor={pleshDark.palette.primary.main}
                />
                <Routes>
                    <Route path='/' element={<Navigate to={'/vote/DU8qj9ybn2o2z6HR88Ut'}/>}/>
                    <Route path='/vote/:id' element={<Vote/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
